<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body>
          <b-card-body>
            <div class="device-top">
              <b-card-title class="text-device mb-0">Abatedor e Ultracongelador Copa</b-card-title>
              <div class="device-right">
                <b-button
                  class="device-btn"
                  variant="none"
                >
                  <feather-icon
                    class="device-icon"
                    icon="CheckSquareIcon"
                  />
                  <span class="align-middle">Adequação ao uso</span>
                </b-button>
                <b-button
                  class="device-btn"
                  variant="none"
                >
                  <feather-icon
                    class="device-icon"
                    icon="CreditCardIcon"
                  />
                  <span class="align-middle">Envolvente</span>
                </b-button>
                <b-button
                  class="device-btn"
                  variant="none"
                >
                  <feather-icon
                    class="device-icon"
                    icon=""
                  />
                  <span class="align-middle">Performance</span>
                </b-button>
                <b-button
                  class="device-btn"
                  variant="none"
                  to="alerts"
                >
                  <feather-icon
                    class="device-icon"
                    icon="BellIcon"
                  />
                  <span class="align-middle">Alertas</span>
                </b-button>
                <b-button
                  class="device-btn"
                  variant="none"
                >
                  <feather-icon
                    class="device-icon"
                    icon="PieChartIcon"
                  />
                  <span class="align-middle">Relatórios</span>
                </b-button>
                <b-button
                  class="device-btn mr-0"
                  variant="none"
                >
                  <feather-icon
                    class="device-icon"
                    icon="CheckSquareIcon"
                  />
                  <span class="align-middle">Detalhes</span>
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card no-body>
          <b-card-body>
            <div class="device-img">
              <img src="https://fakeimg.pl/525x251/" alt="">
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="8"
      >
        <b-card no-body class="device-status">
          <b-card-body>
            <b-row>
              <b-col lg="7">
                <b-card-title class="text-status mb-0">Programa em curso</b-card-title>
                <div class="programa-icons">
                  <div class="icon-program">
                    <img src="@/assets/images/icons/blast-chill.png" alt="" width="60" height="61">
                    <span>Blast chill</span>
                  </div>
                  <div class="icon-program">
                    <img src="@/assets/images/icons/hard.png" alt="">
                    <span>Hard</span>
                  </div>
                  <div class="icon-program mr-0">
                    <img src="@/assets/images/icons/probe.png" alt="" width="53" height="53">
                    <span>Probe</span>
                  </div>
                </div>
              </b-col>
              <b-col class="status-side" lg="5">
                <b-card-title class="text-status mb-0">Device status</b-card-title>
                <div class="status-icons">
                  <div class="icon-status">
                    <img src="@/assets/images/icons/ok.png" alt="">
                    <span>ok</span>
                  </div>
                </div>
                <div class="time-status">
                  <span>05,5 ºC</span>
                  <span>03,0 ºC</span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
}
</script>
