<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body class="adequacao-device">
          <b-card-body>
            <div class="adequacao-top">
              <span class="adequacao-icon">
                <feather-icon
                  icon="CheckSquareIcon"
                />
              </span>
              <span class="text-adequacao">
                  Adequação ao uso
              </span>
              <div class="adequacao-right">
                <b-form-group
                >
                  <v-select
                    class="date-select"
                    placeholder="day / week / month"
                  />
                </b-form-group>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row>
            <b-col cols="12" xl="3">
              <div class="temp-left">
                <span>
                  <feather-icon
                    class="icon-term"
                    icon="ThermometerIcon"
                  />
                </span>
                <span class="text-int">
                    Temperatura interna
                </span>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
            </b-col>
            <b-col cols="12" xl="6">
              <device-info-adequacao-temp-int />
            </b-col>
            <!-- <b-col lg="3">
              <div class="text-legenda d-inline-flex align-items-center">
                <feather-icon
                icon="CircleIcon"
                class="circle-icon mr-50"
                />
                <span>Var. Temperatura interna</span>
              </div>
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import DeviceInfoAdequacaoTempInt from './DeviceInfoAdequacaoTempInt.vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    DeviceInfoAdequacaoTempInt,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>