<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <device-info-abatedor />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <device-info-adequacao />
      </b-col>
    </b-row>    
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import DeviceInfoAbatedor from './DeviceInfoAbatedor.vue'
import DeviceInfoAdequacao from './Adequacao/DeviceInfoAdequacao.vue'

export default {
  components: {
    BRow,
    BCol,
    DeviceInfoAbatedor,
    DeviceInfoAdequacao,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-device.scss';
</style>
